import React from "react";
import * as constant from "../../../constants";
import { FORMAT } from "./types";

type Props = {
  size?: { x: number; y: number };
  className?: string;
  highlight?: FORMAT;
  setHighlight: (din: FORMAT | undefined) => void;
};

const DinAMap = (props: Props): JSX.Element => {
  const { setHighlight, highlight } = props;
  const handleHover = (title: FORMAT) => {
    setHighlight(title);
  };
  return (
    <svg width={`80%`} height={`auto`} viewBox="0 0 1743 1283" version="1.1">
      <g
        id="series_a"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="boxes"
          transform="translate(21.000000, 23.000000)"
          fill="#FFFFFF"
          stroke="#537A8F"
          strokeWidth="3"
        >
          <rect
            id="A10"
            x="1647"
            y="0"
            width="26.6933333"
            height="37"
            fill={highlight === FORMAT.A10 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A10);
            }}
          />
          <polygon
            id="A9"
            transform="translate(1673.500000, 55.500000) rotate(90.000000) translate(-1673.500000, -55.500000) "
            points="1655 29 1693 29 1693 82 1655 82"
            fill={highlight === FORMAT.A9 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A9);
            }}
          />
          <polygon
            id="A8"
            points="1594 0 1647 0 1647 75 1594 75"
            fill={highlight === FORMAT.A8 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A8);
            }}
          />
          <rect
            id="A7"
            transform="translate(1647.000000, 112.500000) rotate(90.000000) translate(-1647.000000, -112.500000) "
            x="1609.5"
            y="59.5"
            width="75"
            height="106"
            fill={highlight === FORMAT.A7 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A7);
            }}
          />
          <rect
            id="A6"
            x="1488"
            y="0"
            width="106"
            height="150"
            fill={highlight === FORMAT.A6 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A6);
            }}
          />
          <rect
            id="A5"
            transform="translate(1594.000000, 225.000000) rotate(90.000000) translate(-1594.000000, -225.000000) "
            x="1519"
            y="119"
            width="150"
            height="212"
            fill={highlight === FORMAT.A5 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A5);
            }}
          />
          <rect
            id="A4"
            x="1276"
            y="0"
            width="212"
            height="300"
            fill={highlight === FORMAT.A4 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A4);
            }}
          />
          <rect
            id="A3"
            transform="translate(1488.000000, 450.000000) rotate(90.000000) translate(-1488.000000, -450.000000) "
            x="1338"
            y="238"
            width="300"
            height="424"
            fill={highlight === FORMAT.A3 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A3);
            }}
          />
          <rect
            id="A2"
            x="851"
            y="0"
            width="425"
            height="600"
            fill={highlight === FORMAT.A2 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A2);
            }}
          />
          <rect
            id="A1"
            transform="translate(1275.500000, 900.500000) rotate(90.000000) translate(-1275.500000, -900.500000) "
            x="975"
            y="476"
            width="601"
            height="849"
            fill={highlight === FORMAT.A1 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A1);
            }}
          />
          <rect
            id="A0"
            x="0"
            y="0"
            width="851"
            height="1201"
            fill={highlight === FORMAT.A0 ? constant.hitGrayHover : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.A0);
            }}
          />
        </g>

        <g
          id="Labels"
          transform="translate(20.000000, 46.000000)"
          fontFamily="NunitoSans,sans-serif"
          fontWeight="500"
        >
          <g id="A0" transform="translate(363.000000, 518.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="46.228" y="63">
                A0
              </tspan>
            </text>
            <text id="841-x-1189" fontSize="36" fill="#6F8494">
              <tspan x="0.004" y="108">
                841 x 1189
              </tspan>
            </text>
          </g>
          <g id="A1" transform="translate(1231.000000, 819.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="37.228" y="63">
                A1
              </tspan>
            </text>
            <text id="594-x-841" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                594 x 841
              </tspan>
            </text>
          </g>
          <g id="A2" transform="translate(993.000000, 218.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="37.228" y="63">
                A2
              </tspan>
            </text>
            <text id="420-x-594" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                420 x 594
              </tspan>
            </text>
          </g>
          <g id="A3" transform="translate(1418.000000, 368.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="37.228" y="63">
                A3
              </tspan>
            </text>
            <text id="297-x-420" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                297 x 420
              </tspan>
            </text>
          </g>
          <g id="A4" transform="translate(1315.000000, 70.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="37.228" y="63">
                A4
              </tspan>
            </text>
            <text id="210-x-297" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                210 x 297
              </tspan>
            </text>
          </g>
          <g id="A5" transform="translate(1526.000000, 140.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="37.228" y="63">
                A5
              </tspan>
            </text>
            <text id="148-x-210" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                148 x 210
              </tspan>
            </text>
          </g>
          <g
            id="A6"
            transform="translate(1524.000000, 0.000000)"
            fill="#345066"
          >
            <text fontSize="36">
              <tspan x="0.222" y="64">
                A6
              </tspan>
            </text>
            <text id="A7" fontSize="36">
              <tspan x="101.222" y="98">
                A7
              </tspan>
            </text>
            <text id="A8" fontSize="24">
              <tspan x="84.648" y="24">
                A8
              </tspan>
            </text>
            <text id="A8-Copy" fontSize="24">
              <tspan x="138.148" y="41">
                A9
              </tspan>
            </text>
          </g>
          <g
            id="Copyright"
            transform="translate(0.000000, 1191.000000)"
            fill="#8093A2"
            fontSize="22"
          >
            <text id="©-2020-Papiergewicht">
              <tspan x="694.061" y="22">
                © 2020 Papiergewichtrechner.de
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DinAMap;
