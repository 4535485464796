import React from "react";
import * as constant from "../../../constants";
import { FORMAT } from "./types";

type Props = {
  size?: { x: number; y: number };
  className?: string;
  highlight?: FORMAT;
  setHighlight: (din: FORMAT | undefined) => void;
};

const DinBMap = (props: Props): JSX.Element => {
  const { setHighlight, highlight } = props;
  const handleHover = (title: FORMAT) => {
    setHighlight(title);
  };
  return (
    <svg width={`80%`} height={`auto`} viewBox="0 0 2096 1488" version="1.1">
      <g
        id="series_b_ctlr"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="boxes"
          transform="translate(24.000000, 23.000000)"
          fill="#FFFFFF"
          stroke="#537A8F"
          strokeWidth="3"
        >
          <rect
            id="B10"
            x="1984"
            y="0"
            width="31.8266667"
            height="44"
            fill={highlight === FORMAT.B10 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B10);
            }}
          />
          <polygon
            id="B9"
            points="1984 44 2048 44 2048 88 1984 88"
            fill={highlight === FORMAT.B9 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B9);
            }}
          />
          <rect
            id="B8"
            x="1920"
            y="0"
            width="63.6533333"
            height="88"
            fill={highlight === FORMAT.B8 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B8);
            }}
          />
          <polygon
            id="B7"
            points="1920 88 2048 88 2048 176 1920 176"
            fill={highlight === FORMAT.B7 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B7);
            }}
          />
          <polygon
            id="B6"
            points="1792 0 1920 0 1920 176 1792 176"
            fill={highlight === FORMAT.B6 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B6);
            }}
          />
          <polygon
            id="B5"
            points="1792 176 2048 176 2048 352 1792 352"
            fill={highlight === FORMAT.B5 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B5);
            }}
          />
          <polygon
            id="B4"
            points="1536 0 1792 0 1792 352 1536 352"
            fill={highlight === FORMAT.B4 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B4);
            }}
          />
          <polygon
            id="B3"
            points="1536 352 2048 352 2048 704 1536 704"
            fill={highlight === FORMAT.B3 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B3);
            }}
          />
          <polygon
            id="B2"
            points="1024 0 1536 0 1536 704 1024 704"
            fill={highlight === FORMAT.B2 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B2);
            }}
          />
          <polygon
            id="B1"
            points="1024 704 2048 704 2048 1408 1024 1408"
            fill={highlight === FORMAT.B1 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B1);
            }}
          />
          <polygon
            id="B0"
            points="0 0 1024 0 1024 1408 0 1408"
            fill={highlight === FORMAT.B0 ? constant.aquaHaze : undefined}
            onMouseEnter={() => {
              handleHover(FORMAT.B0);
            }}
          />
        </g>
        <g
          id="Labels"
          transform="translate(28.000000, 53.000000)"
          fontFamily="NunitoSans,sans-serif"
          fontWeight="500"
        >
          <g id="B0" transform="translate(425.000000, 611.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="54.98" y="63">
                B0
              </tspan>
            </text>
            <text id="1000-x-1414" fontSize="36" fill="#6F8494">
              <tspan x="0.77" y="108">
                1000 x 1414
              </tspan>
            </text>
          </g>
          <g id="B1" transform="translate(1465.000000, 973.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="44.98" y="63">
                B1
              </tspan>
            </text>
            <text id="707-x-1000" fontSize="36" fill="#6F8494">
              <tspan x="0.004" y="108">
                707 x 1000
              </tspan>
            </text>
          </g>
          <g id="B2" transform="translate(1216.000000, 269.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="35.98" y="63">
                B2
              </tspan>
            </text>
            <text id="500-x-707" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                500 x 707
              </tspan>
            </text>
          </g>
          <g id="B3" transform="translate(1719.000000, 432.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="35.98" y="63">
                B3
              </tspan>
            </text>
            <text id="353-x-500" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                353 x 500
              </tspan>
            </text>
          </g>
          <g id="B4" transform="translate(1589.000000, 82.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="35.98" y="63">
                B4
              </tspan>
            </text>
            <text id="250-x-353" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                250 x 353
              </tspan>
            </text>
          </g>
          <g id="B5" transform="translate(1848.000000, 172.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="35.98" y="63">
                B5
              </tspan>
            </text>
            <text id="176-x-250" fontSize="36" fill="#6F8494">
              <tspan x="0.238" y="108">
                176 x 250
              </tspan>
            </text>
          </g>
          <g
            id="B6"
            transform="translate(1830.000000, 33.000000)"
            fill="#345066"
            fontSize="36"
          >
            <text>
              <tspan x="0.52" y="35">
                B6
              </tspan>
            </text>
          </g>
          <text id="B7" fontSize="36" fill="#345066">
            <tspan x="1955.52" y="111">
              B7
            </tspan>
          </text>
          <text id="B8" fontSize="24" fill="#345066">
            <tspan x="1936.18" y="24">
              B8
            </tspan>
          </text>
          <text id="B9" fontSize="24" fill="#345066">
            <tspan x="1999.18" y="44">
              B9
            </tspan>
          </text>
          <g
            id="Copyright"
            transform="translate(0.000000, 1391.000000)"
            fill="#8093A2"
            fontSize="22"
          >
            <text id="©-2020-Papiergewicht">
              <tspan x="869.061" y="22">
                © 2020 Papiergewichtrechner.de
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DinBMap;
