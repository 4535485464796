import React from "react";
import { FORMAT } from "./types";
import * as constant from "../../../constants";

type Props = {
  size?: { x: number; y: number };
  className?: string;
  highlight?: FORMAT;
  setHighlight: (din: FORMAT | undefined) => void;
};

const USMap = (props: Props): JSX.Element => {
  const { setHighlight, highlight } = props;
  const handleHover = (title: FORMAT) => {
    setHighlight(title);
  };
  return (
    <svg width={`80%`} height={`auto`} viewBox="0 0 1988 1343" version="1.1">
      <g id="us" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          id="ANSI-E"
          stroke="#537A8F"
          strokeWidth="3"
          points="20 20 883 20 883 1137 20 1137"
          fill={highlight === FORMAT.ANSIE ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.ANSIE);
          }}
        />
        <polygon
          id="ANSI-F"
          stroke="#537A8F"
          strokeWidth="3"
          points="20 20 731 20 731 1036 20 1036"
          fill={highlight === FORMAT.ANSIF ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.ANSIF);
          }}
        />

        <polygon
          id="ANSI-D"
          stroke="#537A8F"
          strokeWidth="3"
          points="883 579 1747 579 1747 1137 883 1137"
          fill={highlight === FORMAT.ANSID ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.ANSID);
          }}
        />
        <polygon
          id="Broadsheet"
          stroke="#537A8F"
          strokeWidth="3"
          points="883 20 1315 20 1315 579 883 579"
          fill={highlight === FORMAT.ANSIC ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.ANSIC);
          }}
        />
        <polygon
          id="Ledger"
          stroke="#537A8F"
          strokeWidth="3"
          points="1315 300 1747 300 1747 579 1315 579"
          fill={highlight === FORMAT.Ledger ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.Ledger);
          }}
        />
        <polygon
          id="Letter"
          stroke="#537A8F"
          strokeWidth="3"
          points="1315 20 1531 20 1531 300 1315 300"
          fill={highlight === FORMAT.ANSIA ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.ANSIA);
          }}
        />
        <polygon
          id="Legal"
          stroke="#537A8F"
          strokeWidth="3"
          points="1747 20 1963 20 1963 376 1747 376"
          fill={highlight === FORMAT.Legal ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.Legal);
          }}
        />
        <polygon
          id="Executive"
          stroke="#537A8F"
          strokeWidth="3"
          points="1747 376 1931 376 1931 643 1747 643"
          fill={highlight === FORMAT.Executive ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.Executive);
          }}
        />
        <polygon
          id="Statement"
          stroke="#537A8F"
          strokeWidth="3"
          points="1747 643 1887 643 1887 859 1747 859"
          fill={highlight === FORMAT.Statement ? constant.aquaHaze : "white"}
          onMouseEnter={() => {
            handleHover(FORMAT.Statement);
          }}
        />
        <g
          id="Labels"
          transform="translate(28.000000, 112.000000)"
          fontFamily="NunitoSans,sans-serif"
          fontWeight="500"
        >
          <g id="ANSI-F" transform="translate(34.000000, 764.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="2.468" y="63">
                ANSI F
              </tspan>
            </text>
            <text id="28x40-inch" fontSize="36" fill="#6F8494">
              <tspan x="0.95" y="108">
                28 x 40 inch
              </tspan>
            </text>
          </g>
          <g id="ANSI-E" transform="translate(34.000000, 1032.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="1.572" y="63">
                ANSI E
              </tspan>
            </text>
            <text id="inch" fontSize="36" fill="#6F8494">
              <tspan x="0.95" y="108">
                34 x 44 inch
              </tspan>
            </text>
          </g>
          <g id="ANSI-D" transform="translate(895.000000, 876.000000)">
            <text fontSize="64" fill="#345066">
              <tspan x="0.788" y="63">
                ANSI D
              </tspan>
            </text>
            <text id="22-X-34" fontSize="36" fill="#6F8494">
              <tspan x="1" y="115">
                22 X 34
              </tspan>
            </text>
          </g>
          <g
            id="Broadsheet-Ansi-C"
            transform="translate(896.000000, 280.000000)"
          >
            <text id="Broadsheet-ANSI-C" fontSize="48" fill="#345066">
              <tspan x="1" y="47">
                Broadsheet
              </tspan>
              <tspan x="1" y="107">
                ANSI C
              </tspan>
            </text>
            <text id="17-X-22" fontSize="36" fill="#6F8494">
              <tspan x="0" y="156">
                17 X 22
              </tspan>
            </text>
          </g>
          <g id="Ledger-Tabloid" transform="translate(1316.000000, 280.000000)">
            <text fontSize="48" fill="#345066">
              <tspan x="1" y="47">
                Ledger
              </tspan>
              <tspan x="1" y="107">
                Tabloid{" "}
              </tspan>
            </text>
            <text id="11-X-17" fontSize="36" fill="#6F8494">
              <tspan x="0" y="156">
                11 X 17
              </tspan>
            </text>
          </g>
          <g id="Letter-Ansi-A" transform="translate(1316.000000, 0.000000)">
            <text id="Letter-ANSI-A" fontSize="48" fill="#345066">
              <tspan x="1" y="47">
                Letter
              </tspan>
              <tspan x="1" y="107">
                ANSI A
              </tspan>
            </text>
            <text id="8.5-x-11" fontSize="36" fill="#6F8494">
              <tspan x="0" y="156">
                8.5 x 11
              </tspan>
            </text>
          </g>
          <g id="legal" transform="translate(1745.000000, 124.000000)">
            <text id="Legal" fontSize="48" fill="#345066">
              <tspan x="1" y="47">
                Legal
              </tspan>
            </text>
            <text id="8.5-x-14" fontSize="36" fill="#6F8494">
              <tspan x="0" y="102">
                8.5 x 14
              </tspan>
            </text>
          </g>
          <g id="executive" transform="translate(1738.000000, 437.000000)">
            <text id="Executive" fontSize="36" fill="#345066">
              <tspan x="1" y="35">
                Executive
              </tspan>
            </text>
            <text id="7.25-x-10.5" fontSize="28" fill="#6F8494">
              <tspan x="0" y="70">
                7.25 x 10.5
              </tspan>
            </text>
          </g>
          <g id="invoice" transform="translate(1729.000000, 613.000000)">
            <text id="Statement-Half-Lette" fontSize="24" fill="#345066">
              <tspan x="1" y="35">
                Statement
              </tspan>
              <tspan x="1" y="66">
                Half Letter
              </tspan>
            </text>
            <text id="7.25-x-10.5" fontSize="28" fill="#6F8494">
              <tspan x="0" y="110">
                7.25 x 10.5
              </tspan>
            </text>
          </g>
          <g
            id="Copyright"
            transform="translate(0.000000, 1182.000000)"
            fill="#8093A2"
            fontSize="22"
          >
            <text id="©-2020-Papiergewicht">
              <tspan x="869.061" y="22">
                © 2020 Papiergewichtrechner.de{" "}
              </tspan>
            </text>
          </g>
        </g>
      </g>

      <g
        id="us-stroke"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          id="ANSI-F"
          stroke="#537A8F"
          strokeWidth="3"
          points="20 20 731 20 731 1036 20 1036"
        />
        <polygon
          id="ANSI-E"
          stroke="#537A8F"
          strokeWidth="3"
          points="20 20 883 20 883 1137 20 1137"
        />
        <polygon
          id="ANSI-D"
          stroke="#537A8F"
          strokeWidth="3"
          points="883 579 1747 579 1747 1137 883 1137"
        />
        <polygon
          id="Broadsheet"
          stroke="#537A8F"
          strokeWidth="3"
          points="883 20 1315 20 1315 579 883 579"
        />
        <polygon
          id="Ledger"
          stroke="#537A8F"
          strokeWidth="3"
          points="1315 300 1747 300 1747 579 1315 579"
        />
        <polygon
          id="Letter"
          stroke="#537A8F"
          strokeWidth="3"
          points="1315 20 1531 20 1531 300 1315 300"
        />
        <polygon
          id="Legal"
          stroke="#537A8F"
          strokeWidth="3"
          points="1747 20 1963 20 1963 376 1747 376"
        />
        <polygon
          id="Executive"
          stroke="#537A8F"
          strokeWidth="3"
          points="1747 376 1931 376 1931 643 1747 643"
        />
        <polygon
          id="Statement"
          stroke="#537A8F"
          strokeWidth="3"
          points="1747 643 1887 643 1887 859 1747 859"
        />
      </g>
    </svg>
  );
};

export default USMap;
