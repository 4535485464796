import React, { useState } from "react";
import ads from "../../ads";
import Button from "../common";
import { Link } from "react-router-dom";
import Styled from "./styled";

const cookieName = "consent";

function setCookie(name: string, value: any, days: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const giveConsent = () => {
  setCookie(cookieName, "true", 365);
  ads();
};

const Legalize = () => {
  let consent = getCookie(cookieName);
  const [hide, setHide] = useState<boolean>(!!consent);
  if (consent === "true") {
    ads();

    return null;
  }

  return (
    <Styled.Modal style={hide ? { display: "none" } : {}}>
      <div>
        <span>
          Diese Webseite verwendet u.a. Cookies zur Analyse und Verbesserung der
          Webseite, und zum Ausspielen personalisierter Anzeigen. Mehr Infos
          siehe <Link to={"impressum"}>Impressum</Link>.
        </span>
        <br />
        <br />
        <Button
          onClick={() => {
            setHide(true);
            giveConsent();
          }}
        >
          Akzeptieren
        </Button>
      </div>
    </Styled.Modal>
  );
};

export default Legalize;
