import React from "react";
import { Flyer } from "../../../types";

type PaperProps = {
  flyer: Flyer;
};
const Paper = ({ flyer }: PaperProps) => {
  const minimum = 2;
  const maxWidth = 100,
    maxHeight = 100,
    maxX = 420,
    maxY = 420,
    bleed = 20;
  let { width, height } = flyer;
  width = Math.max((width - 1) * (maxWidth / maxX), minimum);
  height = Math.max((height - 1) * (maxHeight / maxY), minimum);

  const offsetWidth = maxWidth / 2 - width / 2 + bleed / 2;
  const offsetHeight = maxHeight / 2 - height / 2 + bleed / 2;

  return (
    <svg width="200px" height="180px" viewBox={`0 0 120 120`}>
      <defs>
        <filter id="shadow3">
          <feDropShadow
            dx="0"
            dy="1"
            stdDeviation="3"
            floodColor="#000"
            floodOpacity="0.2"
          />
        </filter>
      </defs>

      <rect
        x={offsetWidth}
        y={offsetHeight}
        width={width}
        height={height}
        filter="url(#shadow3)"
        style={{ fill: "white" }}
      />

      <text
        x={offsetHeight + height / 2 - 8}
        y={-(offsetWidth + width + 5)}
        width="10"
        height={height}
        fill="#BBBBBB"
        transform="rotate(90)"
        fontSize="8px"
      >
        {flyer.height} mm
      </text>
      <text
        x={offsetWidth + width / 2 - 10}
        y={offsetHeight + height + 10}
        width="64"
        height="8"
        fill="#BBBBBB"
        fontSize="8px"
      >
        {flyer.width} mm
      </text>
    </svg>
  );
};

export default Paper;
