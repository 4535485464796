import styled from "styled-components";
import * as constant from "../../../../constants";
const headline = styled.div``;

const PresetList = styled.div`
  height: 40px;
  margin: 0 0 2rem 0;
  padding: 4px 30px;
  display: flex;
  justify-content: space-between;
  background: #f3f3f3;
  border: 1px solid ${constant.iron};
  border-width: 1px 0;
  @media (max-width: ${constant.bp_med}px) {
    padding: 2px 10px;
  }
`;

const PresetItem = styled.div`
  color: #999999;
  font-size: 0.875rem;
  text-align: center;
  list-style: none;
  border-radius: 7px;
  line-height: 21px;
  border: 2px #97979b;
  padding: 2px 8px;
  background: white;
  height: 20px;
  align-self: center;

  @media (max-width: ${constant.bp_med}px) {
    padding: 4px 10px 4px;
  }

  &:hover {
    cursor: pointer;
    background: rgba(157, 176, 173, 0.65);
  }
`;

const PresetItemActive = styled(PresetItem)`
  background: #dfdfdf;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { headline, PresetList, PresetItem, PresetItemActive };
