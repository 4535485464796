export const hitGray = "#a1acb4";
export const hitGrayHover = "#BFCACC";
export const porcelain = "#e9edee";
export const bigStone = "#112330";
export const tundra = "#4a4a4a";
export const mercury = "#e4e4e4";
export const borderRadius = 2;
export const periwinkleGray = "#cdd8ec";
export const cornflowerLilac = "#ffacac";
export const picklesBluewood = "#355166";
export const aquaHaze = "#EAF3F3";
export const codGray = "#1B1B1B";
export const ceriseRed = "#e64d6d";
export const smaltBlue = "#537A8F";
export const graniteGrey = "#5E6960";
export const waterLeaf = "#A3E6E9";
export const snowDrift = "#EDF3EE";
export const iron = "#ccd2d3";

export const width = "1024";
export const bp_med = "768";
