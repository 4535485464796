import React from "react";
import Styled from "./styled";
import { Flyer, Format } from "../../../../types";

export type Props = {
  handleClick: (f: Format) => void;
  current: Flyer | undefined;
};

const formats: Format[] = [
  { width: 297, height: 420, title: "DIN A3" },
  { width: 210, height: 297, title: "DIN A4" },
  { width: 148, height: 210, title: "DIN A5" },
  { width: 210, height: 99, title: "DIN lang" },
  { width: 105, height: 148, title: "DIN A6" }
];
const PresetSelection = ({ handleClick, current }: Props): JSX.Element => {
  return (
    <>
      <Styled.PresetList>
        {formats.map(f => {
          if (
            current &&
            current.width * current.height === f.width * f.height
          ) {
            return (
              <Styled.PresetItemActive
                key={f.title}
                onClick={() => {
                  handleClick(f);
                }}
              >
                {f.title}
              </Styled.PresetItemActive>
            );
          }
          return (
            <Styled.PresetItem
              key={f.title}
              onClick={() => {
                handleClick(f);
              }}
            >
              {f.title}
            </Styled.PresetItem>
          );
        })}
      </Styled.PresetList>
    </>
  );
};

export default PresetSelection;
