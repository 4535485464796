import React from "react";
import { createGlobalStyle } from "styled-components";
import NunitoSans from "./fonts/NunitoSans-Regular.ttf";
import NunitoSansBold from "./fonts/NunitoSans-Bold.ttf";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "NunitoSans";
  src: url(${NunitoSans}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NunitoSans";
  src: url(${NunitoSansBold}) format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  background-color: white;
  font-family: "Source Code Pro", Arial, Helvetica, sans-serif;
}
`;

function Calculatas() {
  return (
    <div>
      <GlobalStyle />
      <div>THIS IS ONLY CALCULATAS</div>
    </div>
  );
}

export default Calculatas;
