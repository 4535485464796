import * as constant from "../../../../../constants";
import styled from "styled-components";
import { borderRadius } from "../../../../../constants";

const Input = styled.input`
  font-family: NunitoSans, sans-serif;
  font-size: 0.75rem;
  color: ${constant.tundra};
  background: none;
  text-align: right;
  width: 2rem;
  border: 0;

  @media (min-width: ${constant.bp_med}px) {
    width: 3rem;
    font-size: 0.9375rem;
  }
`;

const InputWrapper = styled.div`
  font-size: 0.875rem;
  min-width: 4rem;
  color: ${constant.tundra};
  text-align: right;
  border: 0;
  background-color: ${constant.porcelain};
  border-radius: ${borderRadius}px;
  padding: 2px 4px;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { Input, InputWrapper };
