import React, { useState } from "react";
import Pslider from "../Pslider";
import Button from "../../../common";
import Paper from "../Paper";
import { Flyer, Format } from "../../../../types";
import PresetSelection from "../Preset";
import Styled from "./styled";
type CalculatorFormProps = {
  onSave: (f: Flyer) => void;
};

const CalculatorForm = (props: CalculatorFormProps) => {
  const [flyer, setFlyer] = useState<Flyer>({
    width: 210,
    height: 297,
    grammage: 80,
    pagesCount: 1
  });

  const update = (v: Partial<Flyer> | Format) => {
    setFlyer({
      ...flyer,
      ...v
    });
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.Result>
          <Styled.Box>
            <Paper flyer={flyer} />
          </Styled.Box>
          <Weight flyer={flyer} />
        </Styled.Result>
        <PresetSelection handleClick={update} current={flyer} />
        <Styled.SliderWrap>
          <Pslider
            value={flyer.width}
            min={1}
            max={420}
            measurement={"width"}
            displayName={"Breite"}
            onChange={(v: Partial<Flyer>) => {
              update(v);
            }}
          />
          <Pslider
            value={flyer.height}
            min={1}
            max={420}
            measurement={"height"}
            displayName={"Höhe"}
            onChange={(v: Partial<Flyer>) => {
              update(v);
            }}
          />
          <Pslider
            value={flyer.grammage}
            min={50}
            max={400}
            measurement={"grammage"}
            displayName={"Grammatur"}
            unit={"g/m2"}
            onChange={(v: Partial<Flyer>) => {
              update(v);
            }}
          />
          <Pslider
            value={flyer.pagesCount}
            min={1}
            max={64}
            measurement={"pagesCount"}
            displayName={"Bögen"}
            unit={""}
            onChange={(v: Partial<Flyer>) => {
              update(v);
            }}
          />{" "}
          <Styled.Button>
            <Button
              onClick={() => {
                props.onSave(flyer);
              }}
            >
              Zur Liste hinzufügen
            </Button>
          </Styled.Button>
        </Styled.SliderWrap>
      </Styled.Wrapper>
    </>
  );
};

export default CalculatorForm;

export const flyerWeight = (f: Flyer) => {
  const area = (f.height * f.width) / 1000000;
  return f.pagesCount * (area * f.grammage);
};

type WeightProps = {
  flyer: Flyer;
};

export const Weight = ({ flyer }: WeightProps) => {
  return <Styled.Weight>{formatUnit(flyerWeight(flyer))}</Styled.Weight>;
};

export const formatUnit = (weight: number): string => {
  if (weight >= 1000) {
    return `${(weight / 1000).toFixed(3)} kg`;
  }
  return `${weight.toFixed(2)} g`;
};
