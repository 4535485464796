import styled from "styled-components";
import * as constant from "../../constants";

const FooterWrapper = styled.div`
  background: #5f5c5c;
  width: 100%;
  border-top: 1px solid #b4b8be;
`;

const Footer = styled.div`
  max-width: ${constant.width}px;
  box-sizing: border-box;
  color: ${constant.aquaHaze};
  line-height: 1.5rem;

  @media (max-width: ${constant.bp_med}px) {
    width: 100%;
  }

  margin: 0 auto;
  padding: 0.5rem 2rem 2rem 2rem;
  display: flex;

  ul,
  ul li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  & a {
    text-decoration: none;
    color: ${constant.waterLeaf};
  }
  h3 {
    color: ${constant.aquaHaze};
  }
  @media (max-width: ${constant.bp_med}px) {
    display: block;
  }
`;

const Copyright = styled.div`
  text-align: right;
  background-color: #041019;
  border-top: #313f49 solid 1px;
  color: gray;
  padding: 7px 14px;
  font-size: 0.75rem;
  position: relative;

  & :before {
    display: block;
    position: absolute;
    left: 0;
    top: -25px;
    background: #041019;
    content: " ";
    width: 2.5rem;
    height: 2rem;
  }

  & :after {
    display: block;
    position: absolute;
    right: 0;
    top: -25px;
    background: #041019;
    content: " ";
    width: 2.5rem;
    height: 2rem;
  }
`;

const Half = styled.div`
  width: 46%;
  margin-right: 4%;
  @media (max-width: ${constant.bp_med}px) {
    width: 100%;
    margin: 0;
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default { Wrapper: FooterWrapper, Footer, Copyright, Half };
