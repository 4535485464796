import styled from "styled-components";
import * as constant from "../../../../constants";

const Pslider = styled.div`
  margin: 0 0 1.75rem 0;
  text-align: left;
  color: ${constant.tundra};
`;

const Label = styled.label`
  width: 100%;
  margin: 0 0 0.75rem 0;
  text-align: left;
  display: flex;
`;

const LabelText = styled.span`
  margin-right: 1.125rem;
  display: inline-block;
  min-width: 80px;

`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { Label, Pslider, LabelText };
