import styled from "styled-components";
import * as constant from "../../constants";
import { Link, NavLink } from "react-router-dom";
import { bp_med, ceriseRed } from "../../constants";
const Header = styled.div`
  max-width: ${constant.width}px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  transition: all 0.3s ease;

  ${(props: HeaderProps) =>
    props.scrolled && {
      height: "80px"
    }};
  @media (max-width: ${bp_med}px) {
    height: 50px;
  }
`;
type HeaderProps = {
  scrolled: boolean;
};

const HeaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  background: ${constant.snowDrift};
  top: 0;
  z-index: 2000;
  transition: all 0.3s ease;
  border-bottom-color: ${constant.snowDrift};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  ${(props: HeaderProps) =>
    props.scrolled && {
      borderBottomColor: constant.iron,
      position: "sticky",
      height: "80px"
    }};

  @media (max-width: ${bp_med}px) {
    height: 50px;
    padding: 0;
  }
`;

const Logo = styled(Link)`
  box-shadow: none;
  display: flex;

  &:hover {
    box-shadow: none;
  }
  > img {
    width: 200px;

    @media (max-width: ${bp_med}px) {
      width: 180px;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;
const HeaderLink = styled(NavLink)`
  margin: 0.25rem 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: lowercase;
  letter-spacing: 2px;
  @media (max-width: ${bp_med}px) {
    margin: 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.04);
  }
  &.active {
    color: ${ceriseRed};
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Logo,
  Header,
  Link: HeaderLink,
  Nav,
  Wrapper: HeaderWrapper
};
