import styled from "styled-components";
import * as constant from "../../../../constants";

const Wrapper = styled.div`
  width: 640px;
  margin: 10px auto 1rem;
  @media (max-width: ${constant.bp_med}px) {
    width: 100%;
  }
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
`;

const Weight = styled.span`
  font-weight: 700;
  font-size: 3rem;
  color: ${constant.codGray};
  font-variant-numeric: tabular-nums;
  padding: 0 1rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 1rem auto 0;

  @media (max-width: ${constant.bp_med}px) {
    font-size: 2rem;
  }
`;

const Button = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;
const Result = styled.div`
  height: 280px;
  display: flex;
  align-items: center;
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M 10,-2.55e-7 V 20 Z M -1.1677362e-8,10 H 20 Z'  stroke-width='0.5' stroke='hsla(0, 0%, 90%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(-40,0)' fill='url(%23a)'/></svg>");

  @media (max-width: ${constant.bp_med}px) {
    height: 180px;
    white-space: nowrap;
    padding: 0;
  }
`;
const Box = styled.div`
  margin: 0 0 0 4rem;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  @media (max-width: ${constant.bp_med}px) {
    margin: 0;
  }
`;

const SliderWrap = styled.div`
  padding: 30px;

  @media (max-width: ${constant.bp_med}px) {
    padding:10px;

  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default { Wrapper, Weight, Button, Box, Result, SliderWrap };
