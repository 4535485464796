import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import PapiergewichtRechner from "./PapiergewichtRechner";
import Calculatas from "./Calculatas";
import * as serviceWorker from "./serviceWorker";

let BUILD_TARGET;
if (process.env.REACT_APP_BUILD_TARGET === "calculatas") {
  BUILD_TARGET = Calculatas;
} else {
  BUILD_TARGET = PapiergewichtRechner;
}
ReactDOM.render(<BUILD_TARGET />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
