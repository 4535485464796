import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo.svg";
import Styled from "./styled";
import { debounce } from "./helpers";

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );

    setPrevScrollPos(currentScrollPos);
  }, 100);

  // new useEffect:
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <Styled.Wrapper scrolled={!visible}>
      <Styled.Header scrolled={!visible}>
        <Styled.Logo to="/">
          <img src={Logo} alt="Logo des PapiergewichtRechners" />
        </Styled.Logo>
        <Styled.Nav>
          <Styled.Link to={"/"}>Rechner</Styled.Link>
          <Styled.Link to={"/formate"}>Über die Formate</Styled.Link>
        </Styled.Nav>
      </Styled.Header>
    </Styled.Wrapper>
  );
};

export default Header;
