import styled from "styled-components";

const Button = styled.button`
  padding: 0.375rem 1rem;
  border-radius: 2px;
  border: 0;
  background-color: #464d51;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  outline: 0;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { Button };
