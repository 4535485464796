import React, { useState } from "react";
import CalculatorForm from "./CalculatorForm";
import FlyerList from "./FlyerList";
import { Flyer } from "../../../types";

const Calculator = () => {
  const [flyers, setFlyers] = useState<Flyer[]>([]);

  const deleteItem = (FlyerIndex: number) => {
    const newFlyers = flyers.filter((_, index) => index !== FlyerIndex);
    setFlyers(newFlyers);
  };

  const addItem = (item: Flyer) => {
    setFlyers([...flyers, item]);
  };

  return (
    <>
      <CalculatorForm onSave={addItem} />
      <FlyerList flyers={flyers} onDelete={deleteItem} />
    </>
  );
};

export default Calculator;
