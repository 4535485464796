import React, { useState } from "react";
import * as constant from "../../../constants";

import Styled from "./styled";
import DinAMap from "./DinAMap";
import DinBMap from "./DinBMap";
import USMap from "./USMap";
import FormelPng from "../../../assets/formel.png";
import BeispielPng from "../../../assets/beispiel.png";
import { PresetFormat, FORMAT, UNIT } from "./types";
import { Link } from "react-router-dom";

export class FormatTable {
  private data: PresetFormat[];

  constructor(data: PresetFormat[]) {
    this.data = data;
  }

  public output() {
    return this.data.map(entry => {
      const in2mm = 25.4;
      return {
        ...entry,
        area: (entry.x * entry.y) / 1000000,
        formatInMM:
          entry.unit === UNIT.inch
            ? `${Math.round(entry.x * in2mm * 10) / 10} x ${Math.round(
                entry.y * in2mm * 100
              ) / 100}`
            : null
      };
    });
  }
}
const a = new FormatTable([
  {
    title: "DIN A0",
    x: 841,
    y: 1189,
    type: FORMAT.A0,
    description: "Plakat",
    unit: UNIT.mm
  },
  {
    title: "DIN A1",
    x: 594,
    y: 841,
    type: FORMAT.A1,
    description: "Zeitung",
    unit: UNIT.mm
  },
  {
    title: "DIN A2",
    x: 420,
    y: 594,
    type: FORMAT.A2,
    description: "Poster",
    unit: UNIT.mm
  },
  {
    title: "DIN A3",
    x: 297,
    y: 420,
    type: FORMAT.A3,
    description: "kleines Poster",
    unit: UNIT.mm
  },
  {
    title: "DIN A4",
    x: 210,
    y: 297,
    description: "Standard Briefformat",
    type: FORMAT.A4,
    unit: UNIT.mm
  },
  {
    title: "DIN A5",
    x: 148,
    y: 210,
    type: FORMAT.A5,
    description: "kleines Heft",
    unit: UNIT.mm
  },
  {
    title: "DIN A6",
    x: 105,
    y: 148,
    type: FORMAT.A6,
    description: "Grußkarte, Postkarte",
    unit: UNIT.mm
  },
  {
    title: "DIN A7",
    x: 74,
    y: 105,
    type: FORMAT.A7,
    description: "Taschenkalender",
    unit: UNIT.mm
  },
  {
    title: "DIN A8",
    x: 52,
    y: 74,
    type: FORMAT.A8,
    description: "Aufkleber, Etiketten",
    unit: UNIT.mm
  },
  { title: "DIN A9", x: 37, y: 52, type: FORMAT.A9, unit: UNIT.mm },
  { title: "DIN A10", x: 26, y: 37, type: FORMAT.A10, unit: UNIT.mm }
]).output();

const b = new FormatTable([
  { title: "DIN B0", x: 1000, y: 1414, type: FORMAT.B0, unit: UNIT.mm },
  { title: "DIN B1", x: 707, y: 1000, type: FORMAT.B1, unit: UNIT.mm },
  { title: "DIN B2", x: 500, y: 707, type: FORMAT.B2, unit: UNIT.mm },
  { title: "DIN B3", x: 353, y: 500, type: FORMAT.B3, unit: UNIT.mm },
  { title: "DIN B4", x: 250, y: 353, type: FORMAT.B4, unit: UNIT.mm },
  { title: "DIN B5", x: 176, y: 250, type: FORMAT.B5, unit: UNIT.mm },
  { title: "DIN B6", x: 125, y: 176, type: FORMAT.B6, unit: UNIT.mm },
  { title: "DIN B7", x: 88, y: 125, type: FORMAT.B7, unit: UNIT.mm },
  { title: "DIN B8", x: 62, y: 88, type: FORMAT.B8, unit: UNIT.mm },
  { title: "DIN B9", x: 44, y: 62, type: FORMAT.B9, unit: UNIT.mm },
  { title: "DIN B10", x: 31, y: 44, type: FORMAT.B10, unit: UNIT.mm }
]).output();

const c = new FormatTable([
  { title: "DIN C0", x: 917, y: 1297, unit: UNIT.mm },
  { title: "DIN C1", x: 648, y: 917, unit: UNIT.mm },
  { title: "DIN C2", x: 458, y: 648, unit: UNIT.mm },
  { title: "DIN C3", x: 324, y: 458, unit: UNIT.mm },
  { title: "DIN C4", x: 229, y: 324, unit: UNIT.mm },
  { title: "DIN C5", x: 162, y: 229, unit: UNIT.mm },
  { title: "DIN C6", x: 114, y: 162, unit: UNIT.mm },
  { title: "DIN C7", x: 81, y: 114, unit: UNIT.mm },
  { title: "DIN C8", x: 57, y: 81, unit: UNIT.mm },
  { title: "DIN C9", x: 40, y: 57, unit: UNIT.mm },
  { title: "DIN C10", x: 28, y: 40, unit: UNIT.mm }
]).output();

const us = new FormatTable([
  { title: "ANSI F", x: 28, y: 40, unit: UNIT.inch, type: FORMAT.ANSIF },
  { title: "ANSI E", x: 34, y: 44, unit: UNIT.inch, type: FORMAT.ANSIE },
  { title: "ANSI D", x: 22, y: 34, unit: UNIT.inch, type: FORMAT.ANSID },
  {
    title: "Broadsheet, ANSI C",
    x: 17,
    y: 22,
    unit: UNIT.inch,
    type: FORMAT.ANSIC
  },
  {
    title: "Tabloid, Ledger, ANSI B",
    x: 11,
    y: 17,
    unit: UNIT.inch,
    type: FORMAT.Ledger
  },
  { title: "Legal", x: 8.5, y: 14, unit: UNIT.inch, type: FORMAT.Legal },
  { title: 'Legal 13"', x: 8.5, y: 13, unit: UNIT.inch, type: FORMAT.Legal13 },
  {
    title: "Letter, ANSI A",
    x: 8.5,
    y: 11,
    unit: UNIT.inch,
    type: FORMAT.ANSIA
  },
  {
    title: "Executive",
    x: 7.25,
    y: 10.5,
    unit: UNIT.inch,
    type: FORMAT.Executive
  },
  {
    title: "Statement (half letter)",
    x: 5.5,
    y: 8.5,
    unit: UNIT.inch,
    type: FORMAT.Statement
  },
  {
    title: "Commercial #10",
    x: 4.125,
    y: 9.5,
    unit: UNIT.inch,
    type: FORMAT.Commercial10
  },
  { title: "Monarch", x: 3.875, y: 7.5, unit: UNIT.inch, type: FORMAT.Monarch },
  { title: "5 x 7 Card", x: 5, y: 7, unit: UNIT.inch, type: FORMAT.Card57 },
  { title: "4 x 6 Card", x: 4, y: 6, unit: UNIT.inch, type: FORMAT.Card46 }
]).output();
const highlightStyle = { background: constant.hitGrayHover };
const Formats = () => {
  const [highlight, setHighlight] = useState<FORMAT | undefined>();
  return (
    <Styled.Wrapper>
      <h1>Übersicht der DIN-Formate</h1>
      <p>
        Die Abkürzung DIN steht für{" "}
        <a href="https://www.din.de/">Deutsches Institut für Normung</a>. Das
        Institut hat seinen Sitz in Berlin und veröffentlichtliche am 18. August
        1922 die deutsche Norm für Papierformate als DIN 476. <br />
        Seit dem Jahr 1975 sind die Grundsätze für den Aufbau und die
        Abmessungen der A- und B-Reihe auch international in der ISO 216
        genormt. Im März 2002 wurde diese internationale und europäische Norm
        als DIN EN ISO 216 übernommen. Die C-Reihe ist aktuell durch DIN 472-2
        genormt.
      </p>
      <h2>Format-Reihen</h2>{" "}
      <ul>
        <li>
          <a href="#dina">Vorzugsreihe A oder DIN-A (beschnitten)</a>
        </li>
        <li>
          <a href="#dinb">Zusatzreihe B oder DIN-B (unbeschnittenes Format)</a>
        </li>
        <li>
          <a href="#dinc">Zusatzreihe C oder DIN-C (Versandhüllen)</a>
        </li>
      </ul>
      <h3 id={"dina"}>Die DIN-A-Formate</h3>
      <p>
        Das Verhältnis zwischen Breite und Höhe ist bei allen definierten
        Blattgrössen gleich - es beträgt „1 zu Wurzel aus 2“. Das entspricht
        ungefähr 1 : 1,414. Man erhält das nächstkleinere Format durch halbieren
        der längeren Seite.
      </p>
      <p>
        Der Flächeninhalt des größten Formats A0, beträgt einen Quadratmeter.
      </p>
      <Styled.Row>
        <Styled.Image>
          <DinAMap
            size={{ x: 500, y: 384 }}
            highlight={highlight}
            setHighlight={setHighlight}
          />
        </Styled.Image>
        <Styled.Table>
          <thead>
            <tr>
              <Styled.TableTh>Format</Styled.TableTh>
              <Styled.TableTh>Maße mm</Styled.TableTh>
              <Styled.TableTh>Fläche qm</Styled.TableTh>
              <Styled.TableTh>Verwendung</Styled.TableTh>
            </tr>
          </thead>
          <tbody>
            {a.map(item => (
              <React.Fragment key={item.title}>
                <tr
                  onMouseEnter={() => {
                    setHighlight(item.type);
                  }}
                  style={highlight === item.type ? highlightStyle : undefined}
                >
                  <Styled.TableTd>{item.title}</Styled.TableTd>
                  <Styled.TableTd>
                    {item.x} x {item.y}
                  </Styled.TableTd>
                  <Styled.TableTd>
                    {Math.round(item.area * 1000) / 1000}
                  </Styled.TableTd>
                  <Styled.TableTd>{item.description}</Styled.TableTd>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Styled.Table>
      </Styled.Row>
      <Styled.Section>
        <h3 id={"dinb"}>DIN-B-Formate</h3>
        <p>
          Die Zusatzreihe B beschreibt die unbeschnittenen Druckbogenformate
          ausgehend von der A-Reihe. Ein Format der B-Reihe ist zirka 19% größer
          als das entsprechende Format der A-Reihe.
        </p>
        <Styled.Row>
          <Styled.Image>
            <DinBMap
              size={{ x: 500, y: 384 }}
              highlight={highlight}
              setHighlight={setHighlight}
            />
          </Styled.Image>
          <Styled.Table>
            <thead>
              <tr>
                <Styled.TableTh>Format</Styled.TableTh>
                <Styled.TableTh>Maße mm</Styled.TableTh>
                <Styled.TableTh>Fläche qm</Styled.TableTh>
              </tr>
            </thead>
            <tbody>
              {b.map(item => (
                <React.Fragment key={item.title}>
                  <tr
                    onMouseEnter={() => {
                      setHighlight(item.type);
                    }}
                    style={highlight === item.type ? highlightStyle : undefined}
                  >
                    <Styled.TableTd>{item.title}</Styled.TableTd>
                    <Styled.TableTd>
                      {item.x} x {item.y}
                    </Styled.TableTd>
                    <Styled.TableTd>
                      {Math.round(item.area * 1000) / 1000}
                    </Styled.TableTd>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Styled.Table>
        </Styled.Row>
      </Styled.Section>
      <Styled.Section>
        <h3 id={"dinc"}>DIN C Formate</h3>
        <p>
          Die Zusatzreihe C beschreibt die Maße für Briefumschläge, sowie
          Versand- und Briefhüllen. Ein Format der C-Reihe ist zirka 9% größer
          als das entsprechende Format der A-Reihe. Ein A4-Papierbogen passt
          deshalb in einen C4-Briefumschlag.
        </p>
        <Styled.Table>
          <thead>
            <tr>
              <Styled.TableTh>Format</Styled.TableTh>
              <Styled.TableTh>Maße mm</Styled.TableTh>
              <Styled.TableTh>Fläche qm</Styled.TableTh>
            </tr>
          </thead>
          <tbody>
            {c.map(item => (
              <React.Fragment key={item.title}>
                <tr>
                  <Styled.TableTd>{item.title}</Styled.TableTd>
                  <Styled.TableTd>
                    {item.x} x {item.y}
                  </Styled.TableTd>
                  <Styled.TableTd>
                    {Math.round(item.area * 1000) / 1000}
                  </Styled.TableTd>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Styled.Table>
      </Styled.Section>
      <p>
        Ein wenig aus der Reihe tanzt das Format{" "}
        <b>
          <a href={"https://de.wikipedia.org/wiki/DIN_lang"}>DIN&nbsp;lang</a>
        </b>{" "}
        (105x210)
      </p>
      <h3>US-Amerikanische Formate</h3>
      <p>
        In den USA und Kanada werden Papierformate in drei Kategorien
        unterteilt:{" "}
      </p>
      <p>
        Die ‚loose sizes‘ (zu deutsch etwa: <i>freie Formate</i>) sind
        ursprünglich zollbasiert und folgen keinem logischen System.
      </p>
      <p>
        Die ANSI-Reihe wurde vom American National Standards Institute definiert
        und ist am ehesten mit der A-Reihe vergleichbar. Das Seitenverhältnis
        ist allerdings nicht konstant wie bei der ISO-Norm: es alterniert
        zwischen 1:1,2941 und 1:1,5455. <br />
        Es gibt auch Überschneidungen zwischen den ANSI- und den freien
        Formaten. So ist zum Beispiel ANSI A auch als Letter bekannt. <br />
        Neben ANSI-A/Letter, welches das gebräuchlichste Format in Nordamerika
        ist, sind Legal und Ledger/Tabloid sehr verbreitet.
      </p>
      <p>
        Unter Architekten hat sich die Architectural series (ARCH) etabliert.
        Die Formate der ARCH-Reihe werden z. B. für grossformatige Zeichnungen
        eingesetzt. Die ARCH-Reihe besitzt im Unterschied zur ANSI-Reihe ein
        schmaleres Seitenverhältnis (~ 3:2).
      </p>
      <Styled.Row>
        <Styled.Image>
          <USMap
            size={{ x: 500, y: 384 }}
            highlight={highlight}
            setHighlight={setHighlight}
          />
        </Styled.Image>
        <Styled.Table>
          <thead>
            <tr>
              <Styled.TableTh>Format</Styled.TableTh>
              <Styled.TableTh>Maße inch</Styled.TableTh>
              <Styled.TableTh>Maße mm</Styled.TableTh>
            </tr>
          </thead>
          <tbody>
            {us.map(item => (
              <React.Fragment key={item.title}>
                <tr
                  onMouseEnter={() => {
                    setHighlight(item.type);
                  }}
                  style={highlight === item.type ? highlightStyle : undefined}
                >
                  <Styled.TableTd>{item.title}</Styled.TableTd>
                  <Styled.TableTd>
                    {item.x} x {item.y}
                  </Styled.TableTd>
                  <Styled.TableTd>{item.formatInMM}</Styled.TableTd>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Styled.Table>
      </Styled.Row>
      <h1>Formel zur Berechnung des Papiergewichts</h1>
      <Styled.Formula>
        <p>
          Das Gewicht eines Papierbogens lässt sich einfach berechnen: Länge,
          Breite und Grammatur werden multipliziert und dann durch 10000
          dividiert.
          <br />
          Zu beachten ist, dass Länge und Breite in Zentimetern (cm) und die
          Grammatur in Gramm pro Quadrametern (g/m2) angebenen werden.
        </p>
        <img
          className={"contextImg"}
          width="415px"
          height="71px"
          src={FormelPng}
          alt={"Formel zur Berechnung des Papiergewichts"}
        />
        <br />
        <h4>
          Beispiel-Rechnung: Wieviel wiegt ein A4-Bogen mit 80
          g/m2-Grammatur-Papier?
        </h4>

        <img
          className={"contextImg"}
          width="353px"
          height="71px"
          src={BeispielPng}
          alt={"Beispielrechnung Bogengewicht"}
        />
        <p>Das Ergebnis für diesen Bogen beträgt 4,99 Gramm.</p>

        <p>
          Einfach online berechnen: Hier geht's zum{" "}
          <Link to={"/"}> Papiergewicht-Rechner</Link>
        </p>
      </Styled.Formula>
    </Styled.Wrapper>
  );
};

export default Formats;
