import React, { FunctionComponent } from "react";
import Styled from "./styled";

type ButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const Button: FunctionComponent<ButtonProps> = ({ onClick, children }) => {
  return <Styled.Button onClick={onClick}>{children}</Styled.Button>;
};

export default Button;
