import React from "react";
import Styled from "./styled";

type DirectInputProps = {
  value: number;
  onChange: (v: number) => void;
  unit: string;
};

const DirectInput = ({
  value,
  onChange,
  unit
}: DirectInputProps): JSX.Element => {
  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      let matches = event.target.value.match(/\d+/g);
      onChange(matches ? +matches[0] : 0);
      return;
    }
    onChange(0);
  };
  return (
    <>
      <Styled.InputWrapper>
        <Styled.Input
          maxLength={8}
          type="number"
          value={value}
          onChange={change}
        />
        {unit}
      </Styled.InputWrapper>
    </>
  );
};

export default DirectInput;
