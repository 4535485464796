import React from "react";
import Styled from "./styled";

type Props = {
  children: React.ReactNode;
};

const InnerLayout = ({ children }: Props): JSX.Element => {
  return <Styled.Layout>{children}</Styled.Layout>;
};

export default InnerLayout;
