import styled from "styled-components";
import * as constant from "../../../../constants";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;

  tr > td {
    border-right: 1px solid ${constant.mercury};
    border-bottom: 1px solid ${constant.mercury};
    padding: 0.75rem 0.5rem;
  }

  tr td:first-child {
    width: 1rem;
  }

  tr td:last-child {
    border-right: none;
  }

  tr:last-child td {
  }
`;

const FlyerList = styled.div`
  border-top: 1px solid ${constant.mercury};
  border-bottom: 1px solid ${constant.mercury};
  color: ${constant.tundra};
  padding: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  width: auto;
  font-size: 0.75rem;

  @media (min-width: ${constant.bp_med}px) {
    width: 580px;
    padding: 30px;
    background: white;
    margin: 0 auto 1rem;
    font-size: 0.9375rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      border-bottom: solid ${constant.periwinkleGray};
      border-width: 1px 0;
      margin: 0;
      padding: 0 0 0 0.5rem;
      display: flex;
      justify-content: space-between;
      line-height: 2.5rem;
    }
  }

  button {
    border: 0;
    background: none;
    color: white;
    margin: 0;
    cursor: pointer;
    font-size: 0.75rem;
    padding: 0.5rem 0.5rem;
  }
`;

const TotalWeight = styled.div`
  font-weight: bold;
`;

const X = styled.div`
  display: block;
  width: 1rem;
  height: 1rem;
  transition: transform 0.25s ease-in-out;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1rem;
    height: 0;
    border-top: 3px solid ${constant.cornflowerLilac};
    transform: rotate(45deg);
    transform-origin: center;
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1rem;
    height: 0;
    border-top: 3px solid ${constant.cornflowerLilac};
    transform: rotate(-45deg);
    transform-origin: center;
  }
`;

const Empty = styled.div`
  text-align: center;
  color: ${constant.hitGray};
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default { Table, FlyerList, X, TotalWeight, Empty };
