import { createGlobalStyle } from "styled-components";
import NunitoSans from "./fonts/NunitoSans-Regular.ttf";
import NunitoSansBold from "./fonts/NunitoSans-Bold.ttf";
import NunitoSansBlack from "./fonts/NunitoSans-Black.ttf";
import * as constant from "./constants";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "NunitoSans";
  src: url(${NunitoSans}) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NunitoSans";
  src: url(${NunitoSansBold}) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NunitoSans";
  src: url(${NunitoSansBold}) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NunitoSans";
  src: url(${NunitoSansBlack}) format("truetype");
  font-weight: 900;
  font-style: normal;
}
body {
  background-color: #EDF3EE;
  font-family: "Roboto", sans-serif;
}

h1,h2, h3, h4 {
  margin: 2rem 0 1rem 0;
  color: ${constant.codGray};
}

h1 {
  font-size: 1.3125rem
}

h2 {
  font-size: 1.25rem
}

h3 {
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
}

input[text] {
  font-family: "NunitoSans", sans-serif;
}

p {
  margin: 0 0 1rem  0;  
  line-height: 1.5rem;
}

.contextImg {
  max-width: 100%;
  height: auto;
}

ul, ul li {
  list-style: square;
}

a {
  padding: 0 2px;
  color: ${constant.graniteGrey};
  text-decoration: none;
}
`;
export default GlobalStyle;
