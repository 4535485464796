import styled from "styled-components";
import * as constant from "../../constants";
const Layout = styled.div`
  max-width: ${constant.width}px;
  margin: 0 auto;
  padding: 1rem;

  @media (max-width: ${constant.bp_med}px) {
    width: 100%;
    padding: 0;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { Layout };
