import React from "react";
import Footer from "./components/Footer/index";
import Calculator from "./components/pages/Calculator/Calculator";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Imprint from "./components/pages/Imprint";
import Legalize from "./components/Legalize";
import InnerLayout from "./components/Layout";
import Formats from "./components/pages/formats";
import GlobalStyle from "./GlobalStyle";

function PapiergewichtRechner() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Header />
        <InnerLayout>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Legalize />
                  <Calculator />
                </>
              }
            />
            <Route
              path="/formate"
              element={
                <>
                  {" "}
                  <Legalize />
                  <Formats />
                </>
              }
            />
            <Route path="/impressum" element={<Imprint />} />
          </Routes>
        </InnerLayout>
        <Footer />
      </Router>
    </>
  );
}

export default PapiergewichtRechner;
