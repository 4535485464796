export enum FORMAT {
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,
  A10,
  B0,
  B1,
  B2,
  B3,
  B4,
  B5,
  B6,
  B7,
  B8,
  B9,
  B10,
  ANSIF,
  ANSIE,
  ANSID,
  ANSIC,
  Extra,
  Tabloid,
  Ledger,
  Legal,
  Legal13,
  ANSIA,
  Executive,
  Statement,
  Commercial10,
  Monarch,
  Card57,
  Card46
}

export interface PresetFormat {
  title: string;
  x: number;
  y: number;
  description?: string;
  type?: FORMAT;
  unit: UNIT;
}

export enum UNIT {
  mm,
  inch
}
