import React, { Component } from "react";
import "./Pslider.css";
import ReactSlider from "react-slider";
import DirectInput from "./DirectInput";
import Styled from "./styled";
import { Flyer } from "../../../../types";

export type PsliderProps = {
  value: number;
  onChange: (v: Partial<Flyer>) => void;
  measurement: string;
  unit: string;
  displayName: string;
  min: number;
  max: number;
};

type PsliderState = {
  value: number;
};

class Pslider extends Component<PsliderProps, PsliderState> {
  static defaultProps = {
    value: 1,
    max: 400,
    min: 1,
    unit: "mm",
    label: "Länge",
    onChange: (v: number): void => {}
  };
  constructor(props: PsliderProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = { value: this.props.value };
  }

  onChange(value: number | number[] | null | undefined): void {
    this.setState({ value: value as number });
    this.props.onChange({ [this.props.measurement]: value });
  }

  render() {
    const { value } = this.props;
    const { unit, displayName, min, max } = this.props;
    return (
      <Styled.Pslider>
        <Styled.Label>
          <Styled.LabelText>{displayName}</Styled.LabelText>
          <DirectInput value={value} onChange={this.onChange} unit={unit} />
        </Styled.Label>

        <ReactSlider
          onBeforeChange={() => {
            document.ontouchmove = e => {
              e.preventDefault();
            };
          }}
          onAfterChange={() => {
            document.ontouchmove = () => {
              return true;
            };
          }}
          className={"horizontal-0 horizontal-slider"}
          onChange={this.onChange}
          defaultValue={value}
          value={value}
          min={min}
          max={max}
        />
      </Styled.Pslider>
    );
  }
}

export default Pslider;
