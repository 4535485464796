import styled from "styled-components";
import * as constant from "../../../constants";

const Table = styled.table`
  border-collapse: collapse;
  padding: 2px;
  flex: 1;
  margin: 0 0 1rem 2rem;
  width: 100%;
  font-size: 0.875rem;
  @media (max-width: ${constant.bp_med}px) {
    margin-left: 0;
    padding: 0;
    font-size: 0.75rem;
  }
  tr {
    border-bottom: 1px solid ${constant.aquaHaze};
  }

  tbody tr {
    cursor: cell;
  }
`;
const Wrapper = styled.div`
  padding: 0.75rem;

  @media (min-width: ${constant.bp_med}px) {
    padding: 0;
  }
`;
const TableTh = styled.th`
  text-align: left;
  padding: 6px 12px;
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-weight: 900;
  color: ${constant.smaltBlue};
  background: #fafafa;
`;
const TableTd = styled.td`
  border: 0;
  padding: 10px 12px;
  white-space: nowrap;
`;
const Image = styled.div`
  flex: 1;
  text-align: center;
`;
const Row = styled.div`
  display: flex;

  @media (max-width: ${constant.bp_med}px) {
    display: block;
  }
`;
const Section = styled.section``;
const Formula = styled.div`
  > img {
    margin: 0.5rem 0;
  }

  @media (max-width: ${constant.bp_med}px) {
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Table,
  TableTd,
  TableTh,
  Image,
  Section,
  Row,
  Formula,
  Wrapper
};
