import styled from "styled-components";
import * as constant from "../../constants";

const Modal = styled.div`
  width: 60%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.65);
  position: fixed;
  color: black;
  z-index: 1000;
  padding: 20%;
  text-align: center;
  top: 0;
  left: 0;

  @media (max-width: ${constant.bp_med}px) {
    width: 90%;
    padding: 5%;
  }

  > div {
    bottom: calc(50% - 5rem);
    background-color: white;
    box-shadow: 0 1px 4px #a1acb4;
    padding: 3rem;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { Modal };
