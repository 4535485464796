import React from "react";

const Imprint = () => {
  return (
    <>
      <h3>Impressum</h3>
      <b>Angaben gemäß § 5 TMG</b>
      <p>
        André Darafarin
        <br />
        Vogt-Wells-Kamp 12
        <br />
        22529 Hamburg
        <br />
        E-Mail: andre at happypork.com
      </p>
      <p>Verantwortlich für den Inhalt: André Darafarin</p>
      <p>
        Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: <br />
        DE273060024
      </p>
      <div>
        <p>
          <strong>Datenschutzerklärung</strong>
        </p>
        <p>
          In dieser Datenschutzerklärung informieren wir Sie über die
          Verarbeitung personenbezogener Daten bei der Nutzung dieser Website.
        </p>
        <p>
          <strong>Verantwortlicher</strong>
        </p>
        <p>
          Verantwortlich für die Datenverarbeitung ist
          <br />
          <em>A. Darafarin, Vogt-Wells-Kamp 12, (andre at happypork.com)</em>
        </p>
        <p>
          <strong>Personenbezogene Daten</strong>
        </p>
        <p>
          Personenbezogene Daten sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person (im Folgenden
          "betroffene Person") beziehen. Als identifizierbar wird eine
          natürliche Person angesehen, die direkt oder indirekt, insbesondere
          mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
          Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
          oder mehreren besonderen Merkmalen identifiziert werden kann, die
          Ausdruck der physischen, physiologischen, genetischen, psychischen,
          wirtschaftlichen, kulturellen oder sozialen Identität dieser
          natürlichen Person sind.
        </p>
        <p>
          <strong>Daten beim Websiteaufruf</strong>
        </p>
        <p>
          Wenn Sie diese Website nur nutzen, um sich zu informieren und keine
          Daten angeben, dann verarbeiten wir nur die Daten, die zur Anzeige der
          Website auf dem von Ihnen verwendeten internetfähigen Gerät
          erforderlich sind. Das sind insbesondere:
        </p>
        <ul>
          <li>IP-Adresse</li>
          <li>Datum und Uhrzeit der Anfrage</li>
          <li>jeweils übertragene Datenmenge</li>
          <li>die Website, von der die Anforderung kommt</li>
          <li>Browsertyp und Browserversion</li>
          <li>Betriebssystem</li>
        </ul>
        <p>
          Rechtsgrundlage für die Verarbeitung dieser Daten sind berechtigte
          Interessen gemäß Art. 6 Abs. 1 UAbs. 1 Buchstabe f) DSGVO, um die
          Darstellung der Website grundsätzlich zu ermöglichen.
        </p>
        <p>
          Darüber hinaus können Sie verschiedene Leistungen auf der Website
          nutzen, bei der weitere personenbezogene und nicht personenbezogene
          Daten verarbeitet werden.
        </p>
        <p>
          <strong>Ihre Rechte</strong>
        </p>
        <p>Als betroffene Person haben Sie folgende Rechte:</p>
        <ul>
          <li>
            Sie haben ein Auskunftsrecht bezüglich der Sie betreffenden
            personenbezogenen Daten, die der Verantwortliche verarbeitet (Art.
            15 DSGVO),
          </li>
          <li>
            Sie haben das Recht auf Berichtigung der Sie betreffenden Daten,
            wenn diese unrichtig oder unvollständig gespeichert werden (Art. 16
            DSGVO),
          </li>
          <li>Sie haben das Recht auf Löschung (Art. 17 DSGVO),</li>
          <li>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen (Art. 18 DSGVO),
          </li>
          <li>Sie haben das Recht auf Datenübertragbarkeit (Art. 20 DSGVO),</li>
          <li>
            Sie haben ein Widerspruchsrecht gegen die Verarbeitung Sie
            betreffender personenbezogener Daten (Art. 21 DSGVO),
          </li>
          <li>
            Sie haben das Recht nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden
            Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
            Wirkung entfaltet oder sie in ähnlicher Weise erheblich
            beeinträchtigt (Art. 22 DSGVO),
          </li>
          <li>
            Sie haben das Recht, sich bei einem vermuteten Verstoß gegen das
            Datenschutzrecht bei der zuständigen Aufsichtsbehörde zu beschweren
            (Art. 77 DSGVO). Zuständig ist die Aufsichtsbehörde an Ihrem
            üblichen Aufenthaltsort, Arbeitsplatz oder am Ort des vermuteten
            Verstoßes.
          </li>
        </ul>
        <p>
          <br />
        </p>

        <p>
          <strong>Einsatz von Cookies</strong>
        </p>
        <p>
          Beim Besuch der Website können Cookies auf Ihrem Gerät gespeichert
          werden. Cookies sind kleine Textdateien, die von dem von Ihnen
          verwendeten Browser gespeichert werden. Cookies können keine Programme
          ausführen und auch keine Viren auf Ihr Gerät übertragen. Die Stelle,
          die den Cookie setzt, kann darüber jedoch bestimmte Informationen
          erhalten. Cookies dienen dazu, das Internetangebot
          benutzerfreundlicher zu machen. Mithilfe von Cookies kann
          beispielsweise das Gerät, mit dem diese Website aufgerufen wurde, bei
          einem erneuten Aufruf erkannt werden.
        </p>
        <p>
          Durch die Browsereinstellungen lässt sich das Setzen von Cookies
          einschränken oder verhindern. So kann z. B. nur die Annahme von
          Cookies, die von Drittanbietern stammen, blockiert werden oder aber
          auch die Annahme von allen Cookies. Durch das Blockieren sind jedoch
          möglicherweise nicht mehr alle Funktionen dieser Website nutzbar. Im
          weiteren Text dieser Datenschutzerklärung informieren wir Sie konkret,
          an welchen Stellen und zu welchen Zwecken Cookies auf den Seiten zum
          Einsatz kommen.
        </p>

        <p>
          <strong>Datenschutzerklärung für Google AdSense</strong>
        </p>
        <p>
          Diese Website verwendet Google AdSense, einen Dienst der Google Inc.,
          1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Google
          AdSense ermöglicht die Einbindung von Werbung auf Webseiten. Zu diesem
          Zweck werden von Google Cookies (kleine Textdateien) auf dem Gerät
          gesetzt bzw. bzw. Web-Beacons (unsichtbare Pixel) angezeigt. Diese
          ermöglichen eine Analyse der Websitebesuche. Dank der Cookies für
          Anzeigenvorgaben können Google und seine Partner Nutzern auf Basis der
          Aufrufe dieser Website oder anderer Websites Werbeanzeigen
          bereitstellen. Die dabei erfassten Informationen werden in der Regel
          an einen Server von Google in den USA übertragen und dort gespeichert
          – darunter auch die IP-Adresse. Möglicherweise gibt Google diese
          Informationen an Dritte weiter.
        </p>
        <p>
          Die Verarbeitung der Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          UAbs. 1 Buchstabe f) DSGVO mit dem berechtigten Interesse an der
          Verbesserung des Websiteangebots wie der Werbung.
        </p>
        <p>
          Das Setzen des AdSense-Cookies lässt sich durch Einstellung des
          Browsers verhindern, indem dieser alle Cookies ablehnt. Möglicherweise
          beeinträchtigt das jedoch die Nutzungsmöglichkeiten dieser Website.
          Sie können personalisierte Werbung zudem auf folgender Seite
          deaktivieren:{" "}
          <a href="https://adssettings.google.com/anonymous">
            https://adssettings.google.com/anonymous
          </a>
          .
        </p>
        <p>
          Weitere Informationen zum Datenschutz durch Google finden Sie unter:{" "}
          <a href="https://www.google.com/intl/de/analytics/privacyoverview.html">
            https://www.google.com/intl/de/analytics/privacyoverview.html
          </a>
          .
        </p>

        <p>
          Quelle:
          <a
            href="https://www.anwalt.de/vorlage/muster-datenschutzerklaerung.php"
            rel="nofollow"
          >
            Muster-Datenschutzerklärung von anwalt.de
          </a>
        </p>
      </div>
    </>
  );
};

export default Imprint;
