import React from "react";
import { Link } from "react-router-dom";
import Styled from "./styled";
const Footer = () => {
  return (
    <Styled.Wrapper>
      <Styled.Footer>
        <Styled.Half>
          <h3>Was kann dieser Rechner?</h3>
          <p>
            Dieses Online-Tool kann das Gewicht von Papier und
            Druckerzeugnissen, wie Flyer, Briefpapier usw., in Abhängigkeit von
            verschiedenen Formaten und Papierstärken bzw. Grammaturen berechnen.
          </p>
          <p>
            Die Fummelei mit Taschenrechner und Formeln war gestern: Heute gibt
            es Features, wie eine Vorschau, Vorgaben für Standardformate und die
            Berechnung ganzer Auflagen. Der Rechner hilft Dir bequem bei Planung
            von Porto und Lieferkosten.
          </p>
        </Styled.Half>
        <Styled.Half>
          <h3>Links</h3>
          <ul>
            <li>
              <Link to="/impressum">Impressum</Link>
            </li>
            <li>
              <a href="http://calculatas.com/">English Calculator</a>
            </li>
          </ul>
        </Styled.Half>
      </Styled.Footer>
      <Styled.Copyright>
        <div>&#169; 2021 PapiergewichtRechner.de</div>
      </Styled.Copyright>
    </Styled.Wrapper>
  );
};

export default Footer;
