import React, { ReactElement, useState } from "react";
import { flyerWeight, formatUnit } from "../CalculatorForm";
import { Flyer, SlidableProperty } from "../../../../types";
import Styled from "./styled";
import DirectInput from "../Pslider/DirectInput";
const FlyerList = ({
  flyers,
  onDelete
}: {
  flyers: Flyer[];
  onDelete: (number: number) => void;
}): ReactElement => {
  const [extra, setExtra] = useState<{ printRun: number; additional: number }>({
    printRun: 1,
    additional: 0
  });

  const update = (e: SlidableProperty) => {
    setExtra({
      ...extra,
      ...e
    });
  };

  const hasFlyers = flyers.length > 0;
  return (
    <Styled.FlyerList>
      {!hasFlyers && (
        <Styled.Empty>Diese Liste hat keine Einträge</Styled.Empty>
      )}
      {hasFlyers && (
        <>
          <Styled.Table>
            <tbody>
              {flyers.map((f: Flyer, i) => {
                return (
                  <FlyerRow key={i} index={i} data={f} onDelete={onDelete} />
                );
              })}
              <tr>
                <td />
                <td colSpan={3}>Zusatzgewicht</td>
                <td>
                  <DirectInput
                    value={extra.additional}
                    onChange={v => {
                      update({ additional: v });
                    }}
                    unit={"g"}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={3}>Auflage</td>
                <td>
                  <DirectInput
                    value={extra.printRun}
                    onChange={v => {
                      update({ printRun: v });
                    }}
                    unit={""}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={3}>Gesamtgewicht</td>
                <td>
                  <Total data={flyers} extra={extra} />
                </td>
              </tr>
            </tbody>
          </Styled.Table>
        </>
      )}
    </Styled.FlyerList>
  );
};
FlyerList.defaultProps = {
  flyers: []
};

export default FlyerList;

export const FlyerRow = ({
  data,
  onDelete,
  index
}: {
  data: Flyer;
  onDelete: (number: number) => void;
  index: number;
}) => {
  return (
    <tr>
      <td>
        <button
          onClick={() => {
            onDelete(index);
          }}
        >
          <Styled.X />
        </button>
      </td>
      <td>
        <span>
          {data.width}x{data.height}mm
        </span>
      </td>
      <td>
        <span>{data.grammage}g/m2 </span>
      </td>

      <td>
        <span>
          {data.pagesCount} {data.pagesCount === 1 ? "Bogen" : "Bögen"}
        </span>
      </td>
      <td>
        <span>{formatUnit(flyerWeight(data))}</span>
      </td>
    </tr>
  );
};

FlyerRow.defaultProps = {
  data: {},
  key: 0
};

export const Total = ({
  data,
  extra
}: {
  data: Flyer[];
  extra: { additional: number; printRun: number };
}): React.ReactElement => {
  const flyersWeight = data.reduce((f: any, e: Flyer) => {
    return f + flyerWeight(e);
  }, 0);
  const total = (flyersWeight + extra.additional) * extra.printRun;

  return <Styled.TotalWeight>{formatUnit(total)}</Styled.TotalWeight>;
};

Total.defaultProps = {
  data: []
};
